import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ShopifyLogo from "../../../components/images/shopify"
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import SectionNavigate from '../../../components/widgets/sectionNavigate';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyFacturacionMultiple: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <ShopifyLogo />
            </div>
            <h2>
                Facturación Múltiple
            </h2>
            <h3>
                ¿Cómo empezar a facturar varios pedidos en una factura?
            </h3>
            <p>
                El proceso de facturación múltiple tiene un propósito muy particular. <b>Cuando usas el Admin de Shopify para ventas directas a cliente</b>, en este caso
                es posible que tu cliente solicite todas las facturas de mas de una venta. <br /> En estos casos, cuando es deseable juntar varios pedidos en una sola factura, puedes hacerlo de la siguiente forma.  
            </p>
            <ImageFooted explanation={`En la lista de pedidos de Shopify, al seleccionar varios pedidos podrás acceder a facturación múltiple.`}>
                    <IMG_Shopify file={'billMultiple'} />
            </ImageFooted>
            <h3>
                Facturación de multiples pedidos a la vez
            </h3>
            <ImageFooted explanation={`Facturar múltiples pedidos tiene una vista más limitada, en general, podrás agrupar por Cliente, uso de CFDI y Forma de pago.`}>
                    <IMG_Shopify file={'billMultipleComplete'} />
            </ImageFooted>
            <SectionNavigate previous={`/ecommerce/shopify/facturacion-sencilla`} next={`/ecommerce/shopify/facturacion-global`}/>
        </div>
    </Layout>
)

export default ShopifyFacturacionMultiple;